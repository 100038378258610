import React, {useEffect, useState} from 'react';
import { Link } from 'gatsby';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Thumbs, EffectFade, Autoplay } from 'swiper';
import Slider from 'react-slick';
import axios from 'axios';
import Mq from '../../utils/useMediaQuery';
import Layout from '../components/layout';
import Seo from '../components/seo';
import ListBrand from '../components/list-brand';
import ListLink from '../components/list-link';
import 'swiper/swiper-bundle.min.css';
import Image from '../components/Image';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

SwiperCore.use([Navigation, Thumbs, EffectFade, Autoplay]);
const settings = {
  infinite: true,
  autoplay:true,
  autoplaySpeed: 0,
  speed:50000,
  slidesToShow: 4,
  cssEase: 'linear',
  draggable:false,
  pauseOnFocus:false,
  pauseOnHover:false,
  edgeFriction:0,
  arrows:false,
  responsive: [
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};
const IndexPage = () => {
  const [pageList, setPageList] = useState('');
  // Ajax設定
  const configJson = {
    method: 'get',
    url: '/update/news/list.json',
    headers: {
      Accept: 'application/json',
    },
    data: '',
  };
  // Ajax実行
  const dataFetch = async () => {
    axios(configJson)
      .then((response) => {
        setPageList(response.data.list);
      })
      .catch(() => {
        // console.log(error);
      });
  };
  // Event
  useEffect(() => {
    dataFetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Layout page="top">
      <Seo title="【公式】薪ストーブのmorso(モルソー)・LEDA(レダ)取扱サイト" />
      <section className="mainvisual">
        <Swiper
          effect="fade"
          className=""
          loop
          speed={2000}
          autoplay={{ delay: 4000 }}
          slidesPerView={1}
          watchSlidesVisibility
          watchSlidesProgress
          allowTouchMove={false}
        >
          <SwiperSlide>
            <Link to="/brand/leda/">
              <Image
                className="w-full"
                filename={`${
                  Mq('pc')
                    ? 'top/mainslide-leda.jpg'
                    : 'top/mainslide-leda-sp.jpg'
                }`}
                alt="LEDA"
              />
            </Link>
          </SwiperSlide>
          <SwiperSlide>
            <Link to="/brand/morso/">
              <Image
                className="w-full"
                filename={`${
                  Mq('pc')
                    ? 'top/mainslide-morso.jpg'
                    : 'top/mainslide-morso-sp.jpg'
                }`}
                alt="MORSO"
              />
            </Link>
          </SwiperSlide>
          <SwiperSlide>
            <Link to="/chimney/">
              <Image
                className="w-full"
                filename={`${
                  Mq('pc')
                    ? 'top/mainslide-chimney.jpg'
                    : 'top/mainslide-chimney-sp.jpg'
                }`}
                alt="煙突"
              />
            </Link>
          </SwiperSlide>
          <SwiperSlide>
            <Link to="accessory">
              <Image
                className="w-full"
                filename={`${
                  Mq('pc')
                    ? 'top/mainslide-accessory.jpg'
                    : 'top/mainslide-accessory-sp.jpg'
                }`}
                alt="アクセサリ"
              />
            </Link>
          </SwiperSlide>
        </Swiper>
        <div className="page-link">
          <ListBrand />
          <ListLink />
        </div>
      </section>
      <section className="news">
        <div className="news-wrap">
          <h2 className="title small">
            NEWS<span>お知らせ</span>
          </h2>
          <ul className="news-body">
            {pageList
              ? pageList.reverse().map((list) => (
                  <li key={list.title}>
                    <Link
                      to={`/news/detail/?id=${list.link}`}
                      className="text-link max"
                    >
                      <span>{list.day}</span>
                      <div className="flex items-center">
                        {list.new ? <p className="new mr-4">NEW</p> : ''}
                        <p>{list.title}</p>
                      </div>
                    </Link>
                  </li>
                ))
              : null}
          </ul>
          <Link to="/news/" className="btn">
            お知らせ一覧へ
          </Link>
        </div>
      </section>
      <section className="brand-area">
        <h2 className="title medium">
          BRAND<span>ブランド</span>
        </h2>
        <div className="brand-wrap">
          <div className="brand-content">
            <Link className="image" to="/brand/morso/">
              <Image
                className="full-image"
                filename="top/slide-brand-01.jpg"
                alt="MORSO"
              />
            </Link>
            <div className="text">
              <Image
                className="logo"
                filename="morso.png"
                width={120}
                alt="MORSO"
              />
              <p>
                アンデルセンストーブのフラグシップブランドとして長年日本で販売されているデンマークのブランド。
                <br />
                デンマーク王室御用達の称号を受けており創業160年以上の伝統と最新鋭の技術を兼ね備えたストーブを多数ラインナップしています。
              </p>
            </div>
          </div>
          <div className="brand-content">
            <Link className="image" to="/brand/leda/">
              <Image
                className="full-image"
                filename="top/slide-brand-02.jpg"
                alt="LEDA"
              />
            </Link>
            <div className="text">
              <Image
                className="logo"
                filename="leda.png"
                width={120}
                alt="LEDA"
              />
              <p>
                ドイツ北西部ニーダーザクセン州。その中でも西部のオランダ国境に近い都市レーア（Leer）で1873年にLEDA社は鋳物工場を立ち上げました。それ以来150年近くに亘り工業大国ドイツの鋳物産業を牽引しています。創業当時は薪ストーブではなく産業用の鋳物製品を主としておりそこで培われた高い鋳物生産技術を薪ストーブに活かしています。
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="chimney">
        <h2 className="title medium">
          CHIMNEY<span>煙突について</span>
        </h2>
        <div className="image">
          <Image filename="top/chimney-01.jpg" alt="煙突について" />
        </div>
        <div className="text">
          <p>
            良い燃焼のためには薪ストーブの性能以上に大事ともいわれる煙突の性能。新宮商行のSCS匠なら最高級の材料、施設を用いて二重断熱煙突国内トップクラスの性能を実現。
            <br />
            また性能のみではなく今までなかった美しい見た目を追求した最新鋭の煙突です。
          </p>
          <Link className="text-link en" to="/chimney/">
            VIEW MORE
          </Link>
        </div>
        <Link className="btn" to="/accessory/">
          その他のアクセサリについてはこちら
        </Link>
        <ul className="accessory-content">
          <li>
            <Link
              className="md:hover:opacity-70 duration-300"
              to="/brand/morso/"
            >
              <Image
                className="full-image"
                filename="top/banner1.jpg"
                alt="accessory01"
              />
            </Link>
          </li>
          <li>
            <Link
              className="md:hover:opacity-70 duration-300"
              to="/brand/leda/"
            >
              <Image
                className="full-image"
                filename="top/banner2.jpg"
                alt="accessory02"
              />
            </Link>
          </li>
          <li>
            <Link className="md:hover:opacity-70 duration-300" to="/chimney/">
              <Image
                className="full-image"
                filename="top/banner3.jpg"
                alt="accessory03"
              />
            </Link>
          </li>
          <li>
            <Link className="md:hover:opacity-70 duration-300" to="/accessory/">
              <Image
                className="full-image"
                filename="top/banner4.jpg"
                alt="accessory04"
              />
            </Link>
          </li>
        </ul>
      </section>
      <section className="howto">
        <div className="howto-head">
          <div className="text-area">
            <h2 className="title medium">
              HOW TO<span>初めての方へ</span>
            </h2>
            <p>
              薪ストーブはどこで買えばいい？
              <br />
              どんな薪ストーブを選べばよいか分からない薪ストーブを設置するのにどれくらいの費用がかかる？
              <br />
              輻射式とは？対流式とは？など様々な疑問にお答えします。
            </p>
          </div>
          <div className="link-area">
            <Link className="text-link en white max" to="/beginner/">
              FOR BEGINNER
              <span>初めての方へ</span>
            </Link>
            <Link className="text-link en white max" to="/woodstove/">
              WOOD STOVE
              <span>薪ストーブとは</span>
            </Link>
            <Link
              className="text-link en white max"
              to="/beginner/#maintenance"
            >
              MAINTENANCE
              <span>メンテナンス</span>
            </Link>
          </div>
        </div>
        {Mq('pc') && (
          <Image
            className="howto-foot pc"
            filename="top/howto-02.jpg"
            alt="howto"
          />
        )}
      </section>
      <section className="movie">
        <h2 className="title medium">
          MOVIE<span>ムービー</span>
        </h2>
        <div className="movie-wrap">
          <Swiper
            className="movie-slider"
            speed={1000}
            navigation
            slidesPerView={1.3}
            spaceBetween={50}
            breakpoints={{
              768: {
                slidesPerView: 2.3,
              },
            }}
          >
            {/* トップページの動画掲載数は「最新の5件」。動画を追加する場合は「一番古い動画を削除して、一番上に動画を追加する」 */}
            <SwiperSlide>
              <div className="youtube-wrap">
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/zSY5Fs0_IJA"
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerpolicy="strict-origin-when-cross-origin"
                  allowFullScreen
                />
              </div>
              <p>アウトドアオーブン使用動画18</p>
            </SwiperSlide>
            <SwiperSlide>
              <div className="youtube-wrap">
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/HX6fywjSuKU"
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerpolicy="strict-origin-when-cross-origin"
                  allowFullScreen
                />
              </div>
              <p>アウトドアオーブン使用動画17</p>
            </SwiperSlide>
            <SwiperSlide>
              <div className="youtube-wrap">
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/CrYhhzFLwXA"
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerpolicy="strict-origin-when-cross-origin"
                  allowFullScreen
                />
              </div>
              <p>薪ストーブ動画集(6140CB・3142CB・5660CB)</p>
            </SwiperSlide>
            <SwiperSlide>
              <div className="youtube-wrap">
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/vHTp7H2GLBQ"
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerpolicy="strict-origin-when-cross-origin"
                  allowFullScreen
                />
              </div>
              <p>アウトドアオーブン メンテナンス動画</p>
            </SwiperSlide>
            <SwiperSlide>
              <div className="youtube-wrap">
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/tpv1FLWobXI"
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerpolicy="strict-origin-when-cross-origin"
                  allowFullScreen
                />
              </div>
              <p>グリルフォーノ使用動画2</p>
            </SwiperSlide>
          </Swiper>
          <Link to="/movie/" className="btn">
            動画一覧へ
          </Link>
        </div>
      </section>
      <section className="case">
        <h2 className="title medium">
          CASE STUDY<span>導入事例</span>
        </h2>
        <div className="case-wrap">
          <Slider {...settings}>
            {(() => {
              const elements = [];
              const CASE = ['導入事例1', '導入事例2', '導入事例3', '導入事例4'];

              CASE.forEach((name, i) => {
                elements.push(
                  <div key={name}>
                    <Image
                      filename={`top/slide-case-0${i + 1}.jpg`}
                      alt={`${name}`}
                    />
                  </div>
                );
              });

              return elements.concat(elements);
            })()}
          </Slider>
          <Link to="/works/" className="btn">
            事例一覧へ
          </Link>
        </div>
      </section>
      <section className="showroom">
        <div className="text">
          <h2 className="title medium">
            SHOWROOM<span>ショールーム</span>
          </h2>
          <p>
            アンデルセンストーブ専用ショールームは全国に千葉県松戸市と北海道札幌市の2カ所ございます。
            <br />
            展示しているストーブを見るだけでなく実際に火を焚いている薪ストーブを体験することができます。
          </p>
          <Link to="/showroom/" className="text-link en">
            VIEW MORE
          </Link>
        </div>
        <div className="showroom-list">
          <div>
            <p className="name">北海道ショールーム</p>
            <p>
              〒063-0812 札幌市西区琴似2条7丁目2-1
              <br />
              TEL:011-624-5158 FAX:011-624-5159
              <br />
              営業時間:9:00-17:30
              <br />
              定休日：土曜日、日曜日、祝日 （※要確認）
            </p>
          </div>
          <div>
            <p className="name">松戸ショールーム</p>
            <p>
              〒270-2231 千葉県松戸市稔台6-7-5
              <br />
              TEL:047-361-3800 FAX:047-362-0157
              <br />
              営業時間:10：00～17：00（要ご予約）
              <br />
              定休日:土曜日、日曜日、祝日
              <br />
              ※第2土曜日のみ1週間前までの事前予約にて対応します。
            </p>
          </div>
        </div>
        <div className="md:w-10/12 mx-auto mt-6 md:mt-0">
          <Link to="/agencies/" className="btn white full">
            その他正規代理店についてはこちら
          </Link>
        </div>
      </section>
    </Layout>
  );
};

export default IndexPage;
